'use strict';

function returnScroll() {
    this.scrollValue = document.body.scrollTop || document.documentElement.scrollTop;
}

function animateHeading(element) {
    var scroll = new returnScroll();

    if (element) {
        if (element.offset().top - window.innerHeight < 0 || scroll.scrollValue > 0) {
            TweenMax.to(element, 0.6, {opacity: 1, x: '0px'});
        }
    }
    
}

function animateP(element) {
    if ((element.offset().top - window.innerHeight) < 0) {
        TweenMax.to(element, 0.6, {opacity: 1, y: '0px'});
    }
}

/* calculate difference between top element offset and window height */
function diff(element) {
    return element.offset().top - window.innerHeight;
}

var $poslanie = $('.text_section__poslanie h1'),
    $istota = $('.text_section__istota h1');


$(document).ready(function () {
    if ($poslanie.length > 1) {
        if ($poslanie.offset().top - window.innerHeight < 0 || scroll.scrollValue > 0) {
            TweenMax.to($poslanie, 0.6, {opacity: 1, x: '0px'});
            TweenMax.to($istota, 0.6, {opacity: 1, x: '0px'});
        }

        if ($('.text_section__istota p').offset().top - window.innerHeight < 0 || scroll.scrollValue > 0) {
            TweenMax.to($('.text_section__istota p'), 0.6, {opacity: 1, x: 0});
            TweenMax.to($('.text_section__istota ul'), 0.6, {opacity: 1, x: 0});
            TweenMax.to($('.text_section__poslanie__italics_center'), 0.6, {opacity: 1, x: 0});
            TweenMax.to($('.text_section__poslanie__paragraph'), 0.6, {opacity: 1, x: 0});
        }
    }

    var tl = new TimelineLite();

    tl.to($('.insurance__col__circle'), 0.6, {opacity: 1})
      .to($('.insurance__col__circle'), 1, {rotationY: 720});
});

window.onscroll = function () {
    var scroll = new returnScroll();
   
    if ($poslanie.length >= 1) {
        if (scroll.scrollValue > $poslanie.offset().top - window.innerHeight) {
            TweenMax.to($poslanie, 0.6, {opacity: 1, x: '0px'});
            TweenMax.to($istota, 0.6, {opacity: 1, x: '0px'});
        }

        if (scroll.scrollValue > $('.text_section__istota p').offset().top - window.innerHeight) {
            TweenMax.to($('.text_section__istota p'), 0.6, {opacity: 1, y: '0px'});
            TweenMax.to($('.text_section__istota ul'), 0.6, {opacity: 1, y: 0});
            TweenMax.to($('.text_section__poslanie__italics_center'), 0.6, {opacity: 1, y: 0});
            TweenMax.to($('.text_section__poslanie__paragraph'), 0.6, {opacity: 1, y: 0});
        }
    }

    if ($('.insurance__col__nezivotne').length >= 1) {
        $('.insurance__col__nezivotne').each(function () {            
            if (scroll.scrollValue > $(this).offset().top - window.innerHeight) {
                TweenMax.to($(this), 0.6, {opacity: 1, x: 0});
            }
        });
    }

    if ($('.insurance__col__zivotne').length >= 1) {
        $('.insurance__col__zivotne').each(function () {
            if (scroll.scrollValue > $(this).offset().top - window.innerHeight) {                
                TweenMax.to($(this), 0.6, {opacity: 1, x: 0});
            }
        });
    }


    if (scroll.scrollValue > $('.footer__logo img').offset().top - window.innerHeight) {                
        TweenMax.to($('.footer__logo img'), 1, {opacity: 1, y: 0});
    }
};
