'use strict';

var checkWidth = function () {
    var slidesToShow;
    window.innerWidth < 703 ? slidesToShow = 1 : window.innerWidth < 991 ? slidesToShow = 2 : slidesToShow = 3;
    return slidesToShow;
};

function mandrillPost(person) { // person = object
    var d = $.Deferred();

    $.ajax({
        type: 'POST',
        url: 'https://mandrillapp.com/api/1.0/messages/send.json',
        data: {
            key: 'csJ5tGh_VB3YISnizUyk5Q',
            message: {
                from_email: person.email,
                from_name: person.name,
                to: [
                    {
                        email: '1kuko3@gmail.com',
                        name: 'C&K Group',
                        type: 'to'
                    }
                ],
                auto_text: true,
                subject: person.subj + 'KONTAKTNÝ FORMULÁR',
                text: person.message
            }
        }
    });

    return d.promise();
}

$(document).ready(function () {
    if (window.innerWidth < 767) {
        $('.footer__col__contact').last().hide();
    }

    $('.content__entry, .insurance__col').animate({opacity: 1}, 1000);

    $('.icons__item__img__animate').hover(function () {
        $(this).siblings('div').stop().animate({ 'background-position-x' : '176px'}, 200);
    },  function () {
        $(this).siblings('div').stop().css({ 'background-position-x' : '0px'});
    });

    $('.partners__list').slick({
        slidesToShow: checkWidth(),
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        centerMode: true,
        speed: 6000,
        mobileFirst: true}
    );

    /* Contact form validation and handling */
    $('.footer__col__contact').submit(function () {
        return false;
    });

    $('.footer__col__contact').validate({
        rules: {
            inputName: {required: true, minlength: 2},
            inputEmail: {required: true, email: true},
            inputMessage: {required: true, minlength: 15}
        },
        messages: {
            inputName: 'Prosím zadajte svoje meno',
            inputEmail: 'Prosím zadajte email vo správnom tvare',
            inputMessage: 'Vyplňte, prosím, Vašu správu'
        },

        submitHandler: function () {
            var person = {
                name : $('#inputName').val(),
                email : $('#inputEmail').val(),
                subj : $('#inputSubj').val(),
                message : $('#inputText').val()};

            $.ajax({
                type: 'POST',
                url: 'https://mandrillapp.com/api/1.0/messages/send.json',
                data: {
                    key: 'csJ5tGh_VB3YISnizUyk5Q',
                    message: {
                        from_email: person.email,
                        from_name: person.name,
                        to: [
                            {
                                email: '1kuko3@gmail.com',
                                name: 'C&K Group',
                                type: 'to'
                            }
                        ],
                        auto_text: true,
                        subject: person.subj + 'KONTAKTNÝ FORMULÁR',
                        text: person.message
                    }
                }
            }).done(function () {
                $('.footer__col__contact__sent p').first().text('Vaša správa bola odoslaná');
                console.log('message was sent');
            }).fail(function (err) {
                console.log('Error submitting formular: ' + err);
                $('.footer__col__contact__sent p').first().text('Pri odosielaní správy sa vyskytla chyba!');
            });
        }
    });
});

// lightbox configuratioin

lightbox.option({
    'resizeDuration': 100,
    'fadeDuration': 100,
    'wrapAround': true
});
